import React from "react"

const ColoredLine = ({ color }) => (
    <hr
        style={{
            color: color,
            backgroundColor: color,
            height: 2,
            width: "80%",
            border: "1px solid black",
            borderRadius: "7px 7px 7px 7px",
            marginLeft: "7%",
            marginTop: "25px"
        }}
    />
);

export default ColoredLine