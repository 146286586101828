/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0px 1.0875rem 1.45rem`,
          paddingTop: 0,
        }}

      >
        <main>{children}</main>
        <div
        style={{
          marginTop: "100px",
        }}> 
          <footer style={{
            fontSize: "13px"
          }}>
            © Jan Krebs {new Date().getFullYear()}
            <br/>
            <a href="/impressum" style={{ color: "hsla(0, 0%, 0%, 0.8)", fontSize: "13px", marginLeft: "1.2em", textDecoration: "none" }}>Impressum</a>
          </footer>
        </div>
       
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
